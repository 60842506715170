<template>
  <dashboard-content>
    <div class="card">
      <template v-if="selected.length">
        <div class="card-header flex justify-between items-start">
          <div>
            <div class="card-title">{{ $t('page.templates.sentence.your_templates') }}</div>
            <p class="text-gray-500"
              v-html="$t('page.templates.sentence.your_templates_description-html')"
            />
          </div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.templates.helper-html')"
            />
          </base-popover>
        </div>
        <div class="card-body flex flex-col space-y-6">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 mt-2">
            <template-thumbnail
              v-for="template in selected"
              :key="template.id"
              :template="template"
              hide-selection
              @onToogle="toogle(template.id)"
            />
          </div>
        </div>
      </template>

      <div class="card-header mt-4">
        <div>
          <div class="card-title">{{ $t('page.templates.sentence.all_templates') }}</div>
        </div>
        <base-popover v-if="!selected.length">
          <template v-slot:button>
            <i class="far fa-question-circle text-blue-600"></i>
          </template>
          <div
            class="popover-body"
            v-html="$t('page.analytics.audience_age.helper-html')"
          />
        </base-popover>
      </div>
      <div class="card-body">
        <div
          class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 mt-2"
          v-if="isLoaded"
        >
          <template-thumbnail
            v-for="template in templates"
            :key="template.id"
            :template="template"
            @onToogle="toogle(template.id)"
          />
        </div>

        <div ref="listsEnd"></div>
        <div
          class="flex justify-center items-center space-x-2 w-full my-16"
          v-if="isLoading"
        >
          <i class="fas fa-spinner fa-spin"></i>
          <div>{{ $t('sentence.loading') }}</div>
        </div>
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import BasePopover from '@/components/base/BasePopover.vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import TemplateThumbnail from '@/components/templates/templateThumbnail.vue'
import { useTemplates } from '@/composables/templates/useTemplates'
import { useToast } from '@/composables/useToast'
import { useProfileService } from '@/services/firestore/profile'
import { defineComponent, ref, toRaw } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DashboardContent, BasePopover, TemplateThumbnail },

  setup () {
    const listsEnd = ref(null)

    const {
      isLoaded,
      isLoading,
      templates,
      selected,
      fetchTemplates,
      toogle
    } = useTemplates()

    const { updateProfile } = useProfileService()

    const { t } = useI18n()

    const saveForm = async () => {
      await updateProfile(toRaw(selected))

      useToast.fire({
        icon: 'success',
        title: t('state.update_profile.title'),
        text: t('state.update_profile.message')
      })
    }

    useIntersectionObserver(listsEnd, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        fetchTemplates({
          skip: templates.value.length
        })
      }
    })

    return {
      isLoaded,
      isLoading,
      templates,
      selected,
      listsEnd,
      toogle,
      saveForm
    }
  }
})
</script>

<style scoped>
@layer components {
  .active {
    @apply ring-4 ring-indigo-500;
  }
}
</style>
