
import BasePopover from '@/components/base/BasePopover.vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import TemplateThumbnail from '@/components/templates/templateThumbnail.vue'
import { useTemplates } from '@/composables/templates/useTemplates'
import { useToast } from '@/composables/useToast'
import { useProfileService } from '@/services/firestore/profile'
import { defineComponent, ref, toRaw } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { DashboardContent, BasePopover, TemplateThumbnail },

  setup () {
    const listsEnd = ref(null)

    const {
      isLoaded,
      isLoading,
      templates,
      selected,
      fetchTemplates,
      toogle
    } = useTemplates()

    const { updateProfile } = useProfileService()

    const { t } = useI18n()

    const saveForm = async () => {
      await updateProfile(toRaw(selected))

      useToast.fire({
        icon: 'success',
        title: t('state.update_profile.title'),
        text: t('state.update_profile.message')
      })
    }

    useIntersectionObserver(listsEnd, ([{ isIntersecting }]) => {
      if (isIntersecting) {
        fetchTemplates({
          skip: templates.value.length
        })
      }
    })

    return {
      isLoaded,
      isLoading,
      templates,
      selected,
      listsEnd,
      toogle,
      saveForm
    }
  }
})
